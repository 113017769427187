import React from "react";
import { Link } from "gatsby";
import "./city-markers.css";

const CityMarkers = () => (
  <svg
    width="169"
    height="345"
    viewBox="0 0 169 345"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="markers"
  >
    {/* Loja */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8259 344.824C67.4609 344.824 74.4599 337.808 74.4599 329.154C74.4599 320.5 67.4609 313.484 58.8259 313.484C50.1909 313.484 43.1909 320.5 43.1909 329.154C43.1909 337.808 50.1909 344.824 58.8259 344.824Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1649 328.769C54.6509 332.267 58.8479 338.558 58.8479 338.558C58.8479 338.558 63.7109 330.926 64.7069 328.272H64.7C64.948 327.59 65.084 326.853 65.084 326.084C65.084 322.588 62.2839 319.754 58.8299 319.754C55.3759 319.754 52.5759 322.588 52.5759 326.084C52.5759 327.044 52.7869 327.954 53.1649 328.769ZM58.9609 329.678C60.9749 329.678 62.6089 328.041 62.6089 326.021C62.6089 324.002 60.9749 322.365 58.9609 322.365C56.9459 322.365 55.3129 324.002 55.3129 326.021C55.3129 328.041 56.9459 329.678 58.9609 329.678Z"
        fill="white"
      />
    </Link>
    {/* Cuenca   */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.2219 264.904C82.8569 264.904 89.8569 257.889 89.8569 249.234C89.8569 240.58 82.8569 233.564 74.2219 233.564C65.5879 233.564 58.5879 240.58 58.5879 249.234C58.5879 257.889 65.5879 264.904 74.2219 264.904Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5599 248.845C70.0449 252.343 74.243 258.634 74.243 258.634C74.243 258.634 79.106 251.002 80.101 248.349H80.0949C80.3429 247.666 80.4779 246.929 80.4779 246.16C80.4779 242.664 77.6779 239.83 74.2249 239.83C70.7709 239.83 67.9709 242.664 67.9709 246.16C67.9709 247.12 68.1819 248.03 68.5599 248.845ZM73.8179 249.754C75.8329 249.754 77.4659 248.117 77.4659 246.098C77.4659 244.079 75.8329 242.442 73.8179 242.442C71.8029 242.442 70.17 244.079 70.17 246.098C70.17 248.117 71.8029 249.754 73.8179 249.754Z"
        fill="white"
      />
    </Link>
    {/* Riobamba */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.248 144.247C132.883 144.247 139.882 137.231 139.882 128.577C139.882 119.923 132.883 112.907 124.248 112.907C115.613 112.907 108.613 119.923 108.613 128.577C108.613 137.231 115.613 144.247 124.248 144.247Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.577 128.188C120.063 131.686 124.26 137.977 124.26 137.977C124.26 137.977 129.123 130.345 130.119 127.691H130.112C130.36 127.009 130.496 126.272 130.496 125.503C130.496 122.007 127.696 119.173 124.242 119.173C120.788 119.173 117.988 122.007 117.988 125.503C117.988 126.463 118.199 127.373 118.577 128.188ZM124.373 129.101C126.388 129.101 128.021 127.464 128.021 125.444C128.021 123.425 126.388 121.788 124.373 121.788C122.358 121.788 120.725 123.425 120.725 125.444C120.725 127.464 122.358 129.101 124.373 129.101Z"
        fill="white"
      />
    </Link>
    {/* Guayaquil */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.435 150.514C25.07 150.514 32.07 143.498 32.07 134.843C32.07 126.189 25.07 119.173 16.435 119.173C7.80103 119.173 0.801025 126.189 0.801025 134.843C0.801025 143.498 7.80103 150.514 16.435 150.514Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7711 134.455C12.2561 137.952 16.4531 144.243 16.4531 144.243C16.4531 144.243 21.3171 136.611 22.3121 133.958H22.3051C22.5541 133.276 22.6891 132.539 22.6891 131.769C22.6891 128.273 19.8891 125.439 16.4351 125.439C12.9821 125.439 10.1821 128.273 10.1821 131.769C10.1821 132.729 10.3931 133.639 10.7711 134.455ZM16.0291 135.366C18.0441 135.366 19.6771 133.729 19.6771 131.71C19.6771 129.69 18.0441 128.053 16.0291 128.053C14.0141 128.053 12.3811 129.69 12.3811 131.71C12.3811 133.729 14.0141 135.366 16.0291 135.366Z"
        fill="white"
      />
    </Link>
    {/* Ambato */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.006 94.103C154.64 94.103 161.64 87.087 161.64 78.433C161.64 69.779 154.64 62.763 146.006 62.763C137.371 62.763 130.371 69.779 130.371 78.433C130.371 87.087 137.371 94.103 146.006 94.103Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.341 78.044C141.827 81.542 146.024 87.833 146.024 87.833C146.024 87.833 150.887 80.201 151.882 77.547H151.876C152.124 76.865 152.26 76.128 152.26 75.359C152.26 71.863 149.46 69.029 146.006 69.029C142.552 69.029 139.752 71.863 139.752 75.359C139.752 76.319 139.963 77.229 140.341 78.044ZM146.136 78.957C148.151 78.957 149.784 77.319 149.784 75.3C149.784 73.281 148.151 71.644 146.136 71.644C144.121 71.644 142.488 73.281 142.488 75.3C142.488 77.319 144.121 78.957 146.136 78.957Z"
        fill="white"
      />
    </Link>
    {/* Latacunga */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.853 70.595C117.488 70.595 124.488 63.579 124.488 54.924C124.488 46.27 117.488 39.254 108.853 39.254C100.219 39.254 93.219 46.27 93.219 54.924C93.219 63.579 100.219 70.595 108.853 70.595Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.191 54.536C104.676 58.034 108.873 64.325 108.873 64.325C108.873 64.325 113.736 56.693 114.732 54.039H114.725C114.974 53.357 115.109 52.62 115.109 51.851C115.109 48.355 112.309 45.521 108.855 45.521C105.402 45.521 102.602 48.355 102.602 51.851C102.602 52.811 102.813 53.721 103.191 54.536ZM108.45 55.44C110.464 55.44 112.098 53.803 112.098 51.784C112.098 49.764 110.464 48.127 108.45 48.127C106.435 48.127 104.802 49.764 104.802 51.784C104.802 53.803 106.435 55.44 108.45 55.44Z"
        fill="white"
      />
    </Link>
    {/* Quito */}
    <Link to="#" className="marker">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.607 31.424C161.242 31.424 168.242 24.408 168.242 15.754C168.242 7.09998 161.242 0.0839844 152.607 0.0839844C143.972 0.0839844 136.973 7.09998 136.973 15.754C136.973 24.408 143.972 31.424 152.607 31.424Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.943 15.365C148.428 18.863 152.625 25.154 152.625 25.154C152.625 25.154 157.488 17.522 158.484 14.868H158.477C158.726 14.186 158.861 13.449 158.861 12.68C158.861 9.18396 156.061 6.34998 152.607 6.34998C149.153 6.34998 146.354 9.18396 146.354 12.68C146.354 13.64 146.565 14.55 146.943 15.365ZM152.738 16.269C154.753 16.269 156.386 14.632 156.386 12.613C156.386 10.593 154.753 8.95596 152.738 8.95596C150.724 8.95596 149.09 10.593 149.09 12.613C149.09 14.632 150.724 16.269 152.738 16.269Z"
        fill="white"
      />
    </Link>
  </svg>
);

export default CityMarkers;
