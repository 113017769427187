import ambatoImg from "../images/cities/ambato.jpg";
import cuencaImg from "../images/cities/cuenca.jpg";
import guayaquilImg from "../images/cities/guayaquil.jpg";
import latacungaImg from "../images/cities/latacunga.jpg";
import lojaImg from "../images/cities/loja.jpg";
import quitoImg from "../images/cities/quito.jpg";
import riobambaImg from "../images/cities/riobamba.jpg";

const cities = [
  {
    imgURL: quitoImg,
    topic: "Quito.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: guayaquilImg,
    topic: "Guayaquil.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: cuencaImg,
    topic: "Cuenca.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: lojaImg,
    topic: "Loja.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: riobambaImg,
    topic: "Riobamba.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: ambatoImg,
    topic: "Ambato.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
  {
    imgURL: latacungaImg,
    topic: "Latacunga.js",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit exercitationem repellat molestias quod repudiandae quo atque, nobis saepe ducimus, consectetur voluptates pariatur animi.",
    link: "#",
  },
];

export default cities;
